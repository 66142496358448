//FiREBASE
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDeybkj6JqKoMxMfZRxRcjwQ_DoFVflZsc",
  authDomain: "gourmet-pages.firebaseapp.com",
  projectId: "gourmet-pages",
  storageBucket: "gourmet-pages.firebasestorage.app",
  messagingSenderId: "317158608256",
  appId: "1:317158608256:web:5d06ca08ca3654cb9a0565",
  measurementId: "G-3YNCTG2W1H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
