import "./Footer.css";
import logo from "../../img/logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="left-Footer">
        <div>
          <img
            src={logo}
            height="60px"
            width="46.14px"
            style={{ marginRight: "10px" }}
            alt={"logo"}
          />
          <h2>Gourmet Pages</h2>
        </div>
        <p>© Copyright 2024 | Gourmet Pages</p>
      </div>
      <div className="right-Footer">
        <a href="#home">
          <h3>Home</h3>
        </a>
        <a href="#problem">
          <h3>About</h3>
        </a>
        <a href="#pricing">
          <h3>Pricing</h3>
        </a>
        <a href="#ourwork">
          <h3>Our Work</h3>
        </a>
      </div>
      <div className="mobileFooter">
        <a href="#home">
          <h3>Home</h3>
        </a>
        <a href="#problem">
          <h3>About</h3>
        </a>
        <a href="#pricing">
          <h3>Pricing</h3>
        </a>
        <a href="#ourwork">
          <h3>Our Work</h3>
        </a>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <img
            src={logo}
            height="60px"
            width="46.14px"
            style={{ marginRight: "20px" }}
            alt={"logo"}
          />
          <h2 style={{ display: "inline" }}>Gourmet Pages</h2>
        </div>
        <p>© Copyright 2024 | Gourmet Pages</p>
      </div>
    </div>
  );
};

export default Footer;
