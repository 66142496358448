import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import "./Header.css";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    console.log(showMobileMenu);
  });

  const activeMobileMenu = {
    transform: showMobileMenu ? "translateX(0px)" : "translateX(100%)",
  };

  return (
    <div className="header">
      <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
        <div className="left-navbar">
          <img
            src={logo}
            height="40px"
            style={{ marginRight: "10px" }}
            alt="Gourmet Pages Logo"
          />
          Gourmet Pages
        </div>
      </Link>
      <div className="navbar">
        <a href="#problem">
          <span>ABOUT</span>
        </a>
        <a href="#pricing">
          <span>PRICING</span>
        </a>
        <a href="#ourwork">
          <span>OUR WORK</span>
        </a>
      </div>
      <div>
        <Link to="/contact">
          <button className="headerButton">GET STARTED</button>
        </Link>
        <FontAwesomeIcon
          icon={faBars}
          size="xl"
          className="hamburger-menu"
          onClick={() => {
            setShowMobileMenu(true);
          }}
        />
      </div>
      <div className="mobileMenu" style={activeMobileMenu}>
        <FontAwesomeIcon
          icon={faXmark}
          size="xl"
          className="cross"
          style={{ fontSize: "2em", color: "#ffffff" }}
          onClick={() => {
            setShowMobileMenu(false);
          }}
        />
        <a href="#problem" onClick={() => setShowMobileMenu(false)}>
          <h1 className="mobileMenuItem">ABOUT</h1>
        </a>
        <a href="#pricing" onClick={() => setShowMobileMenu(false)}>
          <h1 className="mobileMenuItem">PRICING</h1>
        </a>
        <a href="#ourwork" onClick={() => setShowMobileMenu(false)}>
          <h1 className="mobileMenuItem">OUR WORK</h1>
        </a>
        <Link to="/contact">
          <h1 className="mobileMenuItem" style={{ color: "#fe6857" }}>
            GET STARTED
          </h1>
        </Link>
      </div>
    </div>
  );
};

export default Header;
