import React, { KeyboardEvent, useState } from "react";
import "./Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { OrbitProgress } from "react-loading-indicators";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

const Form = () => {
  const [count, setCount] = useState(0);
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [basicPackage, setBasicPackage] = useState(true);
  const [showBasicPackage, setShowBasicPackage] = useState(false);
  const [showMarketingPackage, setShowMarketingPackage] = useState(false);
  const [error, setError] = useState(false);

  const handleNextClick = () => {
    switch (count) {
      case 0: {
        setCount(count + 1);
        break;
      }
      case 1: {
        console.log("package");
        setCount(count + 1);
        break;
      }
      case 2: {
        console.log("full name");
        if (validateName(fullName) === false) {
          setError(true);
        } else {
          setError(false);
          setCount(count + 1);
        }
        break;
      }
      case 3: {
        console.log("mobile number");
        if (validateMobileNumber(mobileNumber) === false) {
          setError(true);
        } else {
          setError(false);
          setCount(count + 1);
        }
        break;
      }
      case 4: {
        console.log("email");
        if (validateEmail(email) === false) {
          setError(true);
        } else {
          setError(false);
          setCount(count + 1);
        }
        break;
      }
      case 5: {
        console.log("business name");
        if (validateBusinessName(businessName) === false) {
          setError(true);
        } else {
          setError(false);
          setCount(count + 1);
          submitForm();
          redirect();
        }

        break;
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateName = (name: string): boolean => {
    const nameRegex = /^([a-zA-Z]+)(\s[a-zA-Z]+)*$/;
    return nameRegex.test(name.trim());
  };
  const validateMobileNumber = (name: string): boolean => {
    const mobileNumberRegex = /^\+?[0-9]{1,3}?[-.\s]?[0-9]{7,15}$/;
    return mobileNumberRegex.test(name.trim());
  };
  const validateBusinessName = (businessName: string): boolean => {
    const businessNameRegex = /^(?!\s*$).+/;
    return businessNameRegex.test(businessName.trim());
  };

  const submitForm = () =>
    addDoc(collection(db, "Users"), {
      created: new Date(),
      basicPackage: basicPackage,
      businessName: businessName,
      email: email,
      fullName: fullName,
      mobileNumber: mobileNumber,
    });

  const redirect = () => {
    window.location.href = "https://tidycal.com/gourmetpages/book-a-call";
  };

  const basicStyle = {
    transform: basicPackage ? "scale(1.1)" : "scale(1)",
    backgroundColor: basicPackage ? "white" : "rgb(233, 233, 233)",
  };

  const marketingStyle = {
    transform: basicPackage ? "scale(1)" : "scale(1.1)",
    backgroundColor: basicPackage ? "rgb(233, 233, 233)" : "white",
  };

  const activeBasicSidebar = {
    transform: showBasicPackage ? "translateX(0px)" : "translateX(400px)",
  };
  const activeMarketingSidebar = {
    transform: showMarketingPackage ? "translateX(0px)" : "translateX(400px)",
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleNextClick();
    }
  };

  let stages = [
    {
      Detail: "NA",
      Heading: "Let's get a couple details...",
      Input: <h3>This won't take more than 2 mins.</h3>,
    },
    {
      Detail: "Package",
      Heading: "I am interested in the following package...",
      Input: (
        <div className="packageWrapper">
          <div
            className="packageCard"
            onClick={() => {
              if (basicPackage === false) {
                setBasicPackage(!basicPackage);
              }
            }}
            style={basicStyle}
          >
            <div className="packageCardTop">
              <h3
                style={{
                  margin: "0",
                  paddingTop: "20px",
                }}
              >
                Basic
              </h3>
              <h3
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    color: "#fe6857",
                    fontSize: "1.4em",
                    fontFamily: "Raleway",
                  }}
                >
                  {" "}
                  $970
                </span>
              </h3>
              <p>One-Time Setup Fee</p>
              <p style={{ paddingBottom: "10px" }}>
                A website with an interactive menu and management of Google
                Business information.
              </p>
              <button
                style={{ fontSize: "0.7em" }}
                onClick={() => {
                  setShowBasicPackage(true);
                  setShowMarketingPackage(false);
                }}
              >
                READ MORE
              </button>
            </div>
            <div className="packageCardBottom">
              <h2 style={{ fontSize: "1.5em" }}>$50</h2>
              <p style={{ margin: "0" }}>Monthly Fee</p>
            </div>
          </div>

          {/* MARKETING */}
          <div
            className="packageCard"
            onClick={() => {
              if (basicPackage === true) {
                setBasicPackage(!basicPackage);
              }
            }}
            style={marketingStyle}
          >
            <div className="packageCardTop">
              <h3
                style={{
                  margin: "0",
                  paddingTop: "20px",
                }}
              >
                Marketing
              </h3>
              <h3
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <span
                  style={{
                    color: "#fe6857",
                    fontSize: "1.4em",
                    fontFamily: "Raleway",
                  }}
                >
                  {" "}
                  $1690
                </span>
              </h3>
              <p>One-Time Setup Fee</p>
              <p style={{ paddingBottom: "10px" }}>
                A website with an interactive menu and management of social
                media accounts.
              </p>
              <button
                style={{ fontSize: "0.7em" }}
                onClick={() => {
                  setShowBasicPackage(false);
                  setShowMarketingPackage(true);
                }}
              >
                READ MORE
              </button>
            </div>
            <div className="packageCardBottom">
              <h2 style={{ fontSize: "1.5em" }}>$300</h2>
              <p style={{ marginTop: "0", marginBottom: "0" }}>Monthly Fee</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      Detail: "Full Name",
      Heading: "Full name",
      Input: (
        <div style={{ paddingBottom: "40px" }}>
          <input
            autoFocus
            id="fullName"
            value={fullName}
            onKeyDown={handleEnter}
            onChange={(e) => setFullName(e.target.value)}
          ></input>

          {error && (
            <p style={{ color: "red" }}>
              <FontAwesomeIcon icon={faCircleExclamation} /> Please input a
              valid full name.
            </p>
          )}
        </div>
      ),
    },
    {
      Detail: "Mobile Number",
      Heading: "Mobile Number",
      Input: (
        <div style={{ paddingBottom: "40px" }}>
          <input
            autoFocus
            id="email"
            value={mobileNumber}
            onKeyDown={handleEnter}
            onChange={(e) => setMobileNumber(e.target.value)}
          ></input>
          {error && (
            <p style={{ color: "red" }}>
              <FontAwesomeIcon icon={faCircleExclamation} /> Please input a
              valid mobile number.
            </p>
          )}
        </div>
      ),
    },
    {
      Detail: "Email",
      Heading: "Email",
      Input: (
        <div style={{ paddingBottom: "40px" }}>
          <input
            autoFocus
            id="email"
            value={email}
            onKeyDown={handleEnter}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          {error && (
            <p style={{ color: "red" }}>
              <FontAwesomeIcon icon={faCircleExclamation} /> Please input a
              valid email.
            </p>
          )}
        </div>
      ),
    },
    {
      Detail: "Business Name",
      Heading: "Business Name",
      Input: (
        <div style={{ paddingBottom: "40px" }}>
          <input
            autoFocus
            id="businessName"
            value={businessName}
            onKeyDown={handleEnter}
            onChange={(e) => setBusinessName(e.target.value)}
          ></input>
          {error && (
            <p style={{ color: "red" }}>
              <FontAwesomeIcon icon={faCircleExclamation} /> Please input a
              valid email.
            </p>
          )}
        </div>
      ),
    },

    {
      Detail: "NA",
      Input: (
        <OrbitProgress color="#000000" size="medium" text="" textColor="" />
      ),
    },
  ];

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="formWrapper">
      {showBasicPackage && (
        <FontAwesomeIcon
          icon={faXmark}
          className="cross"
          onClick={() => {
            setShowMarketingPackage(false);
            setShowBasicPackage(false);
          }}
          style={{ fontSize: "2em", color: "#ffffff" }}
        />
      )}
      <div className="sidebar" style={activeBasicSidebar}>
        <div className="card">
          <div className="cardTop">
            <h3
              style={{
                margin: "0",
                paddingTop: "20px",
                paddingBottom: "10px",
              }}
            >
              Basic
              <i className="fa-solid fa-xmark"></i>
            </h3>
            <h2>
              <span
                style={{
                  color: "#b8b8b8",
                  fontSize: "0.7em",
                  textDecoration: "line-through",
                }}
              >
                $1500
              </span>
              <span style={{ color: "#fe6857" }}> $970</span>
            </h2>
            <p>One-Time Setup Fee</p>
            <p style={{ paddingBottom: "10px" }}>
              A website with an interactive menu and management of Google
              Business information.
            </p>
          </div>
          <div className="cardMain">
            <p>
              <span className="accent">Interactive Menu:</span> Keep your
              customers from Uber Eats with an up-to-date menu.
            </p>
            <p>
              <span className="accent">Fast & Responsive:</span> Speedy loading
              times and accessible on all devices.
            </p>
            <p>
              <span className="accent">Modern Design:</span> Customised to your
              restaurant's brand.
            </p>
            <p>
              <span className="accent">Complete Copywriting:</span> Let us
              handle the text and you the food.
            </p>
            <p>
              <span className="accent">Search Engine Optimisation:</span> Made
              to appear high on Google search and drive traffic.
            </p>
            <div className="center">
              <i
                className="fa-solid fa-circle-plus"
                style={{ fontSize: "0.8em" }}
              ></i>
            </div>
            <p>
              <span style={{ color: "#fe6857" }}>
                Need photos? ($250 extra):
              </span>{" "}
              We'll come take professional photos for the website as well as for
              your own marketing use.
            </p>
          </div>
        </div>

        <div className="card" style={{ marginTop: "-10px" }}>
          <div
            className="cardTop"
            style={{
              paddingBottom: "10px",
              paddingTop: "10px",
              borderTop: "1px #dfdfdf solid",
            }}
          >
            <h2 style={{ fontSize: "1.5em", paddingTop: "12px" }}>$50</h2>
            <p style={{ marginTop: "0" }}>Monthly Fee</p>
          </div>
          <div className="cardMain">
            <p>
              <span className="accent">
                Management of Google Business Profile:
              </span>{" "}
              Free changes to trading hours and business information on Google.
            </p>
            <p>
              <span className="accent">Includes Basic Website Changes:</span>{" "}
              Trading hours, menu and images can be changed on request.
            </p>
            <p>
              <span className="accent">Hosting Included:</span> Covers all cost
              to keep the website live.
            </p>
            <p>
              <span className="accent">Security and Speed Monitoring:</span> We
              will make sure your page stays safe and live 24/7.
            </p>
            <p>
              <span className="accent">24/7 Support:</span> Just a text away in
              case of questions or support.
            </p>
          </div>
        </div>
      </div>
      {showMarketingPackage && (
        <FontAwesomeIcon
          icon={faXmark}
          className="cross"
          onClick={() => {
            setShowMarketingPackage(false);
            setShowBasicPackage(false);
          }}
          style={{ fontSize: "2em", color: "#ffffff" }}
        />
      )}
      <div className="sidebar" style={activeMarketingSidebar}>
        <div className="card">
          <div className="cardTop">
            <h3
              style={{
                margin: "0",
                paddingTop: "20px",
                paddingBottom: "10px",
              }}
            >
              Marketing
            </h3>
            <h2>
              <span
                style={{
                  color: "#b8b8b8",
                  fontSize: "0.7em",
                  textDecoration: "line-through",
                }}
              >
                $2100
              </span>
              <span className="accent"> $1,690</span>
            </h2>
            <p>One-Time Setup Fee</p>
            <p style={{ paddingBottom: "10px" }}>
              A website with an interactive menu, management of social media
              accounts and a professional photoshoot.
            </p>
          </div>
          <div className="cardMain">
            <p>
              <span className="accent"> Professional Photoshoot:</span> We'll
              take professional videos and photos for use on your website and
              social accounts.
            </p>
            <p>
              <span className="accent">Facebook and Instagram Setup:</span>{" "}
              Whether you have social accounts or not, we will bring them up to
              digital marketing standards.
            </p>
            <p>
              <span className="accent">Interactive Menu:</span> Keep your
              customers from Uber Eats with an up-to-date menu.
            </p>
            <p>
              <span className="accent">Fast & Responsive:</span> Speedy loading
              times and accessible on all devices.
            </p>
            <p>
              <span className="accent">Modern Design:</span> Customised to your
              restaurant's brand.
            </p>
            <p>
              <span className="accent">Complete Copywriting:</span> Let us
              handle the text and you the food.
            </p>
            <p>
              <span className="accent">Search Engine Optimisation:</span> Made
              to appear high on Google search and drive traffic.
            </p>
          </div>
        </div>

        <div
          className="card"
          style={{ marginTop: "-10px", borderTop: "#dfdfdf solid 1px" }}
        >
          <div
            className="cardTop"
            style={{ paddingBottom: "10px", paddingTop: "10px" }}
          >
            <h2 style={{ fontSize: "1.5em", paddingTop: "12px" }}>$300</h2>
            <p style={{ marginTop: "0" }}>Monthly Fee</p>
          </div>
          <div className="cardMain">
            <p>
              <span className="accent">Monthly Sales Promotion:</span> Option to
              conduct a monthly sales promotion to help increase sales in
              quieter periods for your business.
            </p>
            <p>
              <span className="accent">Facebook and Instagram Management:</span>{" "}
              1+ post per week on each platform
            </p>
            <p>
              <span className="accent">
                Management of Google Business Profile:
              </span>{" "}
              Free changes to trading hours and business information on Google.
            </p>
            <p>
              <span className="accent">Includes Basic Changes:</span> Trading
              hours, menu and images can be changed on request.
            </p>
            <p>
              <span className="accent">Hosting Included:</span> Covers all cost
              to keep the website live.
            </p>
            <p>
              <span className="accent">Security and Speed Monitoring:</span> We
              will make sure your page stays safe and live 24/7.
            </p>
            <p>
              <span className="accent">24/7 Support:</span> Just a text away in
              case of questions or support.
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <h2>{stages[count].Heading}</h2>
        {stages[count].Input}
      </form>
      <div className="buttonWrapper">
        {count > 0 && count < 6 && (
          <button onClick={() => setCount(count - 1)} className="backButton">
            BACK
          </button>
        )}
        {count < 6 && (
          <button onClick={handleNextClick} className="nextButton">
            NEXT
          </button>
        )}
      </div>
    </div>
  );
};

export default Form;
