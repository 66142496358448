import "./About.css";
import { Link } from "react-router-dom";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const About = () => {
  return (
    <div id="about">
      <div className="aboutWrapper">
        <div className="left-about">
          <LiteYouTubeEmbed
            id="UqyitXmipCc"
            title="Melbourne Website Developer Testimonial"
          />
        </div>
        <div className="right-about">
          <h2>Why Choose Us?</h2>
          <p>My name is Dylan and I am the founder of Gourmet Pages.</p>
          <p>
            I made my first website for a restaurant at the age of 16 and have
            been making websites for all types of businesses since. I have put
            together a team of information technology and marketing students, in
            an aim to build systems which help small businesses.
          </p>
          <p>
            When one of my clients told me of the cut that Uber Eats takes on a
            single order, I could not believe it. As inflation rose, I noticed
            my friends and family move away from Uber Eats and towards ordering
            from restaurants directly again. It was at this point that I
            realised that restaurants that did not have a digital presence were
            suffering.
          </p>
          <p>
            We hope to partner with these businesses, building them websites
            which work to bring them new customers and establish an online
            presence.
          </p>
          <p>Please watch the testimonial from one of my clients, Phil.</p>

          <Link to="/contact">
            <button>GET STARTED</button>
          </Link>
        </div>
      </div>
      <div className="mobileAbout">
        <h2>Why Choose Us?</h2>
        <p>My name is Dylan and I am the founder of Gourmet Pages.</p>
        <p>
          I made my first website for a restaurant at the age of 16 and have
          been making websites for all types of businesses since. I have put
          together a team of information technology and marketing students, in
          an aim to build systems which help small businesses.
        </p>
        <p>
          When one of my clients told me of the cut that Uber Eats takes on a
          single order, I could not believe it. As inflation rose, I noticed my
          friends and family move away from Uber Eats and towards ordering from
          restaurants directly again. It was at this point that I realised that
          restaurants that did not have a digital presence were suffering.
        </p>
        <p>
          We hope to partner with these businesses, building them websites which
          work to bring them new customers and establish an online presence.
        </p>
        <p>Please watch the testimonial from one of my clients, Phil.</p>
        <div className="iFrameWrapper">
          <LiteYouTubeEmbed
            id="UqyitXmipCc"
            title="Melbourne Website Developer Testimonial"
          />
          {/* 
          <iframe
            width="100%"
            loading="lazy"
            height="100%"
            title="aboutVideo"
            src="https://www.youtube.com/embed/UqyitXmipCc?si=DqIyXBqENz1j_X6H"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            style={{ position: "absolute", top: 0, left: 0, border: 0 }}
          ></iframe> */}
        </div>

        <Link to="/contact">
          <button>GET STARTED</button>
        </Link>
      </div>
    </div>
  );
};

export default About;
