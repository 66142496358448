import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Problem.css";
import {
  faFile,
  faTruckFast,
  faCircleUser,
  faIcons,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Problem = () => {
  return (
    <div id="problem">
      <h2>
        The Reason Tech is <span className="accent">FAILING</span> Your Business
      </h2>
      <div className="problemCardWrapper">
        <div className="problemCard">
          <div className="iconWrapper">
            <FontAwesomeIcon icon={faTruckFast} size="2xl" />
          </div>
          <div>
            <h3 style={{ margin: 0 }}>Delivery Apps</h3>
            <p>
              High fees through delivery apps mean you have to raise prices to
              get your fair share. Help give your customers another option like
              direct online ordering.
            </p>
          </div>
        </div>

        <div className="problemCard">
          <div className="iconWrapper">
            <FontAwesomeIcon icon={faFile} size="2xl" />
          </div>
          <div>
            <h3 style={{ margin: 0 }}>Outdated Menus</h3>
            <p>
              Outdated menus hurt your online presence. Keep your customers away
              from Uber Eats by maintaining an up-to-date menu on your website.
            </p>
          </div>
        </div>

        <div className="problemCard">
          <div className="iconWrapper">
            <FontAwesomeIcon icon={faCircleUser} size="2xl" />
          </div>
          <div>
            <h3 style={{ margin: 0 }}>Google Business Account</h3>
            <p>
              Up-to-date trading hours and managed Google Reviews will draw more
              customers to your business.
            </p>
          </div>
        </div>

        <div className="problemCard">
          <div className="iconWrapper">
            <FontAwesomeIcon icon={faIcons} size="2xl" />
          </div>
          <div>
            <h3 style={{ margin: 0 }}>Social Media</h3>
            <p>
              Running a business and managing social media accounts is next to
              impossible. Allow us to develop your accounts to help promote your
              business.
            </p>
          </div>
        </div>

        <div className="problemCard">
          <div className="iconWrapper">
            <FontAwesomeIcon icon={faPercent} size="2xl" />
          </div>
          <div>
            <h3 style={{ margin: 0 }}>Online Promotions</h3>
            <p>
              The state of the cost of living crisis has made bringing in new
              customers tricky. Allow us to run online promotions to draw in new
              and repeat customers.
            </p>
          </div>
        </div>

        <div className="problemCard"></div>
      </div>

      <Link to="/contact">
        <button>GET STARTED</button>
      </Link>
    </div>
  );
};

export default Problem;
