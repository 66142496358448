import "./Landing.css";
import demo from "../../img/demo.webp";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <div className="topSection" id="home">
      <Header />
      <div className="headingSection">
        <div className="leftHeadingSection">
          <h1>
            A <span className="accent">web design</span> team for your{" "}
            <span className="accent">restaurant</span>
          </h1>
          <h4>Handling web design for Melbourne restaurants</h4>

          <Link to="/contact">
            <button>GET STARTED</button>
          </Link>
        </div>

        <img
          src={demo}
          className="landingImage"
          rel="LCP"
          alt="Melbourne Restaurant Website Demo"
        />
      </div>
      <div className="mobileLandingImageWrapper">
        <img
          src={demo}
          className="mobileLandingImage"
          rel="LCP"
          alt="Melbourne Restaurant Website Demo"
        />
      </div>
    </div>
  );
};

export default Landing;
