import React from "react";
import Problem from "../components/Problem/Problem";
import About from "../components/About/About";
import Pricing from "../components/Pricing/Pricing";
import OurWork from "../components/OurWork/OurWork";
import Footer from "../components/Footer/Footer";
import Landing from "../components/Landing/Landing";
import { Helmet } from "react-helmet";
import image from "../img/image.png";

const Home = () => {
  const title = "Gourmet Pages - Web Design for Melbourne Restaurant";
  const description =
    "We provide web design and marketing solutions for restaurants in the Melbourne area. A digital presence is essential for customers to find your business and to identify your menu and prices.";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={description} />
        {/* Open Graph tags (OG) */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* OG image tags */}
        <meta property="og:image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:alt" content={`Image of ${title} site`} />
      </Helmet>
      <Landing />
      <Problem />
      <About />
      <Pricing />
      <OurWork />
      <Footer />
    </div>
  );
};

export default Home;
