import React from "react";
import { Helmet } from "react-helmet";
import demo from "../../img/demo.webp";

const PreloadLCPImage: React.FC = () => {
  return (
    <Helmet>
      <link rel="preload" as="image" href={demo} />
    </Helmet>
  );
};

export default PreloadLCPImage;
