import { Link } from "react-router-dom";
import Form from "../../components/Form/Form";
import logo from "../../img/logo.png";
import "./Contact.css";
import { Helmet } from "react-helmet";
import image from "../../img/image.png";

const Contact = () => {
  const title = "Gourmet Pages - Contact";
  const description =
    "We provide web design and marketing solutions for restaurants in the Melbourne area. A digital presence is essential for customers to find your business and to identify your menu and prices.";

  return (
    <div className="getStartedSection">
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={description} />
        {/* Open Graph tags (OG) */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* OG image tags */}
        <meta property="og:image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:alt" content={`Image of ${title} site`} />
      </Helmet>
      <Link
        to="/"
        style={{
          color: "inherit",
          textDecoration: "inherit",
        }}
      >
        <div className="logoWrapper">
          <img src={logo} height="40px" style={{ marginRight: "10px" }} />
          Gourmet Pages
        </div>
      </Link>
      <Form />
    </div>
  );
};

export default Contact;
