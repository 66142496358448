import "./OurWork.css";
import "../../hover.css";
import TLBMH from "../../img/tlbmh-1.webp";
import ZAIKA from "../../img/zaika-1.webp";
import PEC from "../../img/pec-1.webp";
import { Link } from "react-router-dom";

const OurWork = () => {
  return (
    <div id="ourwork">
      <h2>Our Work</h2>
      <div className="workWrapper">
        <div className="workPanel">
          <img
            src={TLBMH}
            width="100%"
            alt="The Little Banh Mi House Restaurant Website in Melbourne"
            loading="lazy"
          />
          <div className="workText">
            <h4>The Little Banh Mi House</h4>
          </div>
        </div>
        <div className="workPanel">
          <img
            src={PEC}
            width="100%"
            alt="Penn English College Website in Melbourne"
            loading="lazy"
          />
          <div className="workText">
            <h4>Penn English College</h4>
          </div>
        </div>
        <div className="workPanel">
          <img
            src={ZAIKA}
            width="100%"
            alt="Zaika Indian Restaurant Website in Melbourne"
            loading="lazy"
          />
          <div className="workText">
            <h4>Zaika Indian Restaurant</h4>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "40px", textAlign: "center" }}>
        <Link to="/contact">
          <button>GET STARTED</button>
        </Link>
      </div>
    </div>
  );
};

export default OurWork;
