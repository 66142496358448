import { Link } from "react-router-dom";
import "./Pricing.css";

const Pricing = () => {
  return (
    <div id="pricing">
      <h2>How We Can Help</h2>
      <div className="cardWrapper">
        <div className="left-CardWrapper">
          <div className="card">
            <div className="cardTop">
              <h3
                style={{
                  margin: "0",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Basic
              </h3>
              <h2>
                <span
                  style={{
                    color: "#b8b8b8",
                    fontSize: "0.7em",
                    textDecoration: "line-through",
                  }}
                >
                  $1500
                </span>
                <span style={{ color: "#fe6857" }}> $970</span>
              </h2>
              <p>One-Time Setup Fee</p>
              <p style={{ paddingBottom: "10px" }}>
                A website with an interactive menu and management of Google
                Business information.
              </p>

              <Link to="/contact">
                <button style={{ marginBottom: "20px" }}>GET STARTED</button>
              </Link>
            </div>
            <div className="cardMain">
              <p>
                <span className="accent">Interactive Menu:</span> Keep your
                customers from Uber Eats with an up-to-date menu.
              </p>
              <p>
                <span className="accent">Fast & Responsive:</span> Speedy
                loading times and accessible on all devices.
              </p>
              <p>
                <span className="accent">Modern Design:</span> Customised to
                your restaurant's brand.
              </p>
              <p>
                <span className="accent">Complete Copywriting:</span> Let us
                handle the text and you the food.
              </p>
              <p>
                <span className="accent">Search Engine Optimisation:</span> Made
                to appear high on Google search and drive traffic.
              </p>
              <div className="center">
                <i
                  className="fa-solid fa-circle-plus"
                  style={{ fontSize: "0.8em" }}
                ></i>
              </div>
              <p>
                <span style={{ color: "#fe6857" }}>
                  Need photos? ($250 extra):
                </span>{" "}
                We'll come take professional photos for the website as well as
                for your own marketing use.
              </p>
            </div>
          </div>

          <div className="card" style={{ marginTop: "-10px" }}>
            <div
              className="cardTop"
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                borderTop: "1px #dfdfdf solid",
              }}
            >
              <h2 style={{ fontSize: "1.5em", paddingTop: "12px" }}>$50</h2>
              <p style={{ marginTop: "0" }}>Monthly Fee</p>
            </div>
            <div className="cardMain">
              <p>
                <span className="accent">
                  Management of Google Business Profile:
                </span>{" "}
                Free changes to trading hours and business information on
                Google.
              </p>
              <p>
                <span className="accent">Includes Basic Website Changes:</span>{" "}
                Trading hours, menu and images can be changed on request.
              </p>
              <p>
                <span className="accent">Hosting Included:</span> Covers all
                cost to keep the website live.
              </p>
              <p>
                <span className="accent">Security and Speed Monitoring:</span>{" "}
                We will make sure your page stays safe and live 24/7.
              </p>
              <p>
                <span className="accent">24/7 Support:</span> Just a text away
                in case of questions or support.
              </p>
            </div>
          </div>
        </div>

        <div className="right-CardWrapper">
          <div className="card">
            <div className="cardTop">
              <h3
                style={{
                  margin: "0",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Marketing
              </h3>
              <h2>
                <span
                  style={{
                    color: "#b8b8b8",
                    fontSize: "0.7em",
                    textDecoration: "line-through",
                  }}
                >
                  $2100
                </span>
                <span className="accent"> $1,690</span>
              </h2>
              <p>One-Time Setup Fee</p>
              <p style={{ paddingBottom: "10px" }}>
                A website with an interactive menu, management of social media
                accounts and a professional photoshoot.
              </p>
              <Link to="/contact">
                {" "}
                <button style={{ marginBottom: "20px" }}>GET STARTED</button>
              </Link>
            </div>
            <div className="cardMain">
              <p>
                <span className="accent"> Professional Photoshoot:</span> We'll
                take professional videos and photos for use on your website and
                social accounts.
              </p>
              <p>
                <span className="accent">Facebook and Instagram Setup:</span>{" "}
                Whether you have social accounts or not, we will bring them up
                to digital marketing standards.
              </p>
              <p>
                <span className="accent">Interactive Menu:</span> Keep your
                customers from Uber Eats with an up-to-date menu.
              </p>
              <p>
                <span className="accent">Fast & Responsive:</span> Speedy
                loading times and accessible on all devices.
              </p>
              <p>
                <span className="accent">Modern Design:</span> Customised to
                your restaurant's brand.
              </p>
              <p>
                <span className="accent">Complete Copywriting:</span> Let us
                handle the text and you the food.
              </p>
              <p>
                <span className="accent">Search Engine Optimisation:</span> Made
                to appear high on Google search and drive traffic.
              </p>
            </div>
          </div>

          <div
            className="card"
            style={{ marginTop: "-10px", borderTop: "#dfdfdf solid 1px" }}
          >
            <div
              className="cardTop"
              style={{ paddingBottom: "10px", paddingTop: "10px" }}
            >
              <h2 style={{ fontSize: "1.5em", paddingTop: "12px" }}>$300</h2>
              <p style={{ marginTop: "0" }}>Monthly Fee</p>
            </div>
            <div className="cardMain">
              <p>
                <span className="accent">Monthly Sales Promotion:</span> Option
                to conduct a monthly sales promotion to help increase sales in
                quieter periods for your business.
              </p>
              <p>
                <span className="accent">
                  Facebook and Instagram Management:
                </span>{" "}
                1+ post per week on each platform
              </p>
              <p>
                <span className="accent">
                  Management of Google Business Profile:
                </span>{" "}
                Free changes to trading hours and business information on
                Google.
              </p>
              <p>
                <span className="accent">Includes Basic Changes:</span> Trading
                hours, menu and images can be changed on request.
              </p>
              <p>
                <span className="accent">Hosting Included:</span> Covers all
                cost to keep the website live.
              </p>
              <p>
                <span className="accent">Security and Speed Monitoring:</span>{" "}
                We will make sure your page stays safe and live 24/7.
              </p>
              <p>
                <span className="accent">24/7 Support:</span> Just a text away
                in case of questions or support.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Link to="/contact">
        <button>GET STARTED</button>
      </Link>
    </div>
  );
};

export default Pricing;
